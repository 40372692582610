// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    // apiURL:'http://localhost:4300/',
    // apiURL:'https://api.ralphsconcrete.com/',
    // apiURL:'http://18.220.219.176:4300/',
    apiURL : 'https://api-stage.ralphsconcrete.com/',
    // apiURL:'http://3.13.220.193:4300/',
    client_secret : 'RCP2524'


};

/*
 * For easier debugging in dev0elopment mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
